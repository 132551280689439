import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import { DialogContext } from '../contexts/DialogContext';
import { AirtableContext } from '../contexts/AirtableContext';
import { CircularProgress, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import PrintIcon from '@material-ui/icons/Print';
import EditIcon from '@material-ui/icons/Edit';
import { Redirect } from 'react-router-dom';
import AchatProductInfo from './components/AchatProductInfo';

const styles = (theme) => ({
  paper: {
    maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography?.fontSize,
  },
  block: {
    display: 'block',
  },
  addUser: {
    marginRight: theme.spacing(1),
  },
  contentWrapper: {
    margin: '40px 16px',
  },
  fullHeight: {
    height: "90%",
  },
});

const PopupForm = ({ url }) => {
  return (
    <React.Fragment>
      <CircularProgress />
      <script src="https://static.airtable.com/js/embed/embed_snippet_v1.js"></script>
      <iframe className="airtable-embed airtable-dynamic-height" src={url} frameBorder="0" width="100%" style={{ height: "calc(100% - 80px)", position: "absolute", left: "0", right: "0", bottom: "0", top: "80px", "background": "transparent", "border": "1px solid #ccc" }}></iframe>
    </React.Fragment>
  );
};

const PopupFormAchat = () => {
  return (
    <PopupForm url={"https://airtable.com/embed/shrg1bObtQr6Ffbpv?backgroundColor=pink"} />
  );
}

const PopupFormAchatLineItem = (id) => {
  return (
    <PopupForm url={"https://airtable.com/embed/shrDQW7JDNxXJYfo0?backgroundColor=pink&prefill_Achat=" + id} />
  );
}

const PopupAchatInfo = ({ id }) => {
  const { achatDetails, getAchatDetails, loadingAchatDetails } = React.useContext(AirtableContext);

  React.useEffect(() => {
    getAchatDetails(id);
  }, []);

  return (
    <React.Fragment>
      {loadingAchatDetails && (<CircularProgress />)}
      {loadingAchatDetails === false && (
        <React.Fragment>
          {
            achatDetails.map(achatDetail =>
              <AchatProductInfo key={achatDetail.id} achatDetail={achatDetail} />
            )
          }
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const Content = (props) => {
  const { classes } = props;

  const [redirectId, setRedirect] = React.useState(false);

  const { open, setClassName, setOnExitCallback } = React.useContext(DialogContext);
  const { fetchTodaysAchats, achats, loadingData } = React.useContext(AirtableContext);

  const onExit = () => {
    fetchTodaysAchats();
  };

  React.useEffect(() => {
    setClassName(classes.fullHeight);
    setOnExitCallback(onExit);
    fetchTodaysAchats();
  }, []);

  const openForm = () => {
    open("Nouvelle entrée dans le stock", "Veuillez remplir le formulaire", PopupFormAchat);
  };

  const openFormAchatLine = (id) => {
    open("Ajouter une produit à la commande", "Veuillez remplir le formulaire", () => PopupFormAchatLineItem(id));
  };

  const openEditPage = (id) => {
    open("Information commande", "Ci-dessous les informations du bon de commande fournisseur", () => <PopupAchatInfo id={id} />);
  };

  if (redirectId) {
    return <Redirect to={"print/" + redirectId} />
  }

  return (
    <React.Fragment>
      <Paper className={classes.paper}>
        <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
          <Toolbar>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs>
                <TextField
                  fullWidth
                  placeholder="La liste commande du jour"
                  disabled
                  InputProps={{
                    disableUnderline: true,
                    className: classes.searchInput,
                  }}
                />
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary" className={classes.addUser} onClick={openForm}>
                  Ajouter une commande
                </Button>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <div className={classes.contentWrapper}>
          {
            loadingData === true 
            ? (
              <CircularProgress />
            )
            : (
                achats && achats.length === 0 && loadingData === false
                ? (
                  <Typography color="textSecondary" align="center">
                    Aucune commande reçu aujourd'hui..
                  </Typography>
                )
                : (
                  <TableContainer>
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Modifier</TableCell>
                          <TableCell>Nom du fournisseur</TableCell>
                          <TableCell align="right">Date d'arrivée</TableCell>
                          <TableCell align="right">Nombre de produit</TableCell>
                          <TableCell align="right">Coût total de la commande</TableCell>
                          <TableCell align="right">Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {
                          achats.map(achat => (
                            <TableRow key={achat.id}>
                              <TableCell>
                                <IconButton color="primary" aria-label="add" onClick={() => openEditPage(achat.id)} disabled={achat.products === 0}>
                                  <EditIcon />
                                </IconButton>
                              </TableCell>
                              <TableCell>{achat.name}</TableCell>
                              <TableCell align="right">{new Date(achat.date).toLocaleString()}</TableCell>
                              <TableCell align="right">{achat.products}</TableCell>
                              <TableCell align="right">{achat.total.toFixed(2)} €</TableCell>
                              <TableCell align="right">
                                <IconButton color="primary" aria-label="add" onClick={() => openFormAchatLine(achat.id)}>
                                  <AddShoppingCartIcon />
                                </IconButton>
                                <IconButton color="default" aria-label="print" onClick={() => setRedirect(achat.invoiceNbr)} disabled={achat.products === 0}>
                                  <PrintIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))
                        }
                      </TableBody>
                    </Table>
                  </TableContainer>
                )
            )
          }
          
        </div>
      </Paper>
    </React.Fragment>
  );
}

Content.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Content);