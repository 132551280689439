import React from "react";
import Airtable from "airtable";

export const AirtableContext = React.createContext({});

const getBase = () => {
  var base = new Airtable({
    apiKey:
      "patrs8lVKl22iLD9l.ab87e44f04e6340727a5d8a26fb3fbbb7c7d55e3818d33d9f8616d5ccf215124",
  }).base("appcRatkPgTbTXvtp");
  return base;
};

// Dont forget to add this at the root of your project around your <App />
export default class ProvideAirtableContext extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      fournisseurs: [],
      achats: [],
      achatDetails: [],
      loadingData: false,
      loadingAchatDetails: false,
      updateAchatLineItem: (id, qt, price) => {
        var base = getBase();

        return new Promise((resolver) => {
          base("AchatLineItem").update(
            [
              {
                id: id,
                fields: {
                  Quantité: qt,
                  Prix: price,
                },
              },
            ],
            function (err, records) {
              if (err) {
                resolver(false);
                return;
              }
              resolver(true);
            }
          );
        });
      },
      getAchatDetails: (id) => {
        this.setState({ achatDetails: [] });
        this.loadDataFromAirtable(
          "AchatLineItem",
          (record) =>
            this.setState({
              achatDetails: [
                ...this.state.achatDetails,
                {
                  id: record.id,
                  name: record.get("Name"),
                  qt: record.get("Quantité"),
                  price: record.get("Prix"),
                },
              ],
            }),
          {
            filterByFormula: 'AchatRecordId = "' + id + '"',
          }
        );
        console.log('AchatRecordId = "' + id + '"');
      },
      loadInformations: () => {
        this.state.loadProducts();
        this.state.loadFournisseurs();
      },
      fetchTodaysAchats: () => {
        this.setState({ achats: [] });
        this.loadDataFromAirtable(
          "Achats",
          (record) =>
            this.setState({
              achats: [
                ...this.state.achats,
                {
                  id: record.id,
                  name: record.get("Nom (from Fournisseur)"),
                  date: record.get("Date"),
                  products: record.get("Nombre de produit"),
                  total: record.get("Prix total marchandise"),
                  invoiceNbr: record.get("Numéro Facture"),
                  endDate: record.get("DLC"),
                },
              ],
            }),
          { view: "Du jour" }
        );
      },
      loadProducts: () => {
        this.loadDataFromAirtable("Produits", (record) =>
          this.setState({
            products: [
              ...this.state.products,
              { id: record.id, name: record.get("Nom") },
            ],
          })
        );
      },
      loadFournisseurs: () => {
        this.loadDataFromAirtable("Fournisseurs", (record) =>
          this.setState({
            fournisseurs: [
              ...this.state.fournisseurs,
              {
                id: record.id,
                name: record.get("Nom"),
                vat: record.get("TVA"),
                phone: record.get("Téléphone"),
              },
            ],
          })
        );
      },
      getBase: () => {
        var base = new Airtable({
          apiKey:
            "patrs8lVKl22iLD9l.ab87e44f04e6340727a5d8a26fb3fbbb7c7d55e3818d33d9f8616d5ccf215124",
        }).base("appcRatkPgTbTXvtp");
        return base;
      },
    };
  }

  loadDataFromAirtable = (table, callback, filters) => {
    var base = getBase();

    this.setState({ loadingData: true });

    base(table)
      .select(filters)
      .eachPage(
        (records, fetchNextPage) => {
          records.forEach((record) => {
            callback(record);
          });
          // To fetch the next page of records, call `fetchNextPage`.
          // If there are more records, `page` will get called again.
          // If there are no more records, `done` will get called.
          fetchNextPage();
        },
        (err) => {
          if (err) {
            console.error(err);
            return;
          }
          this.setState({ loadingData: false });
        }
      );
  };

  componentDidUpdate() {}

  componentDidMount() {}

  render() {
    return (
      <React.Fragment>
        <AirtableContext.Provider value={this.state}>
          {this.props.children}
        </AirtableContext.Provider>
      </React.Fragment>
    );
  }
}
