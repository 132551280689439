import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Grid, Typography, Button, CircularProgress } from "@material-ui/core";
import { Redirect, useParams } from "react-router-dom";
import PrintIcon from "@material-ui/icons/Print";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { useBarcode } from "react-barcodes";
import Airtable from "airtable";
import { CustomizedSnackBarContext } from "../contexts/CustomizedSnackbarContext";
import { AirtableContext } from "../contexts/AirtableContext";

const useStyles = makeStyles((theme) => ({
  root: {},
  imgNumeroBeEG: {
    textAlign: "right",
  },
  rowHeight: {
    height: "134px",

    // border: "black",
    // borderWidth: "1px",
    // borderStyle: "solid",
  },
  lastRowHeight: {
    height: "108px",

    // border: "black",
    // borderWidth: "1px",
    // borderStyle: "solid",
  },
  textProdName: {
    lineHeight: "18px",
    fontSize: "18px",
  },
  text: {
    lineHeight: "8px",
    fontSize: "8px",
  },
  barcodeImg: {
    maxHeight: "80px",
  },
  gridItemQRCode: {
    paddingLeft: "25px",
  },
  gridItemTexts: {
    // textAlign: "right",
    width: "100%",
  },
  lineBreak: {
    pageBreakAfter: "always",
  },
  printBtn: {
    position: "fixed",
    top: "5px",
    right: "50%;",
  },
  paddL: {
    maringLeft: "35px",
  },
  exitBtn: {
    position: "fixed",
    top: "5px",
    left: "50%;",
  },
  pageBreak: {
    marginTop: "1rem",
    display: "block",
    pageBreakBefore: "auto",
  },
  ...theme.common,
}));

/// Doc https://github.com/Bunlong/react-barcodes#parameters
const Barcode = ({ className, value }) => {
  const { inputRef } = useBarcode({
    value,
    width: 1,
  });

  return <img alt="barcode" className={className} ref={inputRef} />;
};

const PrintPage = () => {
  const classes = useStyles();
  let { id } = useParams();

  const { errorMessage } = React.useContext(CustomizedSnackBarContext);
  const { achats, getBase } = React.useContext(AirtableContext);

  const [redirect, setRedirect] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [rows, setRows] = React.useState([]);

  console.log("acahts", achats);

  const achat = achats.filter((x) => x.invoiceNbr === id)[0];

  React.useEffect(() => {
    var base = getBase();

    let results = [];

    base("AchatLineItem")
      .select({
        maxRecords: 9999,
        filterByFormula: "Achat='" + id + "'",
        sort: [{ field: "LotProd", direction: "asc" }],
      })
      .eachPage(
        (records, fetchNextPage) => {
          records.forEach(function (record) {
            let qt = record.get("Quantité");

            /// If the product is 'Blanc de poulet' it is transformed so we have a 20% loss
            if (
              record.get("ProdId") &&
              record.get("ProdId").length === 1 &&
              record.get("ProdId")[0] === 1
            ) {
              qt = Math.round(qt * 0.8);
            }

            // Add multiple times (qt/10) the bar code to be displayed enough times
            const barcodeCount = qt / 10 + 3;
            for (let i = 0; i < barcodeCount; i++) {
              results.push({
                id: record.get("barcode"),
                name: record.get("ProductShortName"),
                lotNbr: record.get("LotProd"),
              });
            }
          });
          fetchNextPage();
        },
        (err) => {
          if (err) {
            console.error(err);
            errorMessage(err);

            setTimeout(() => {
              setRedirect(true);
            }, 2500);

            return;
          }

          setRows(results);
          setLoading(false);
        }
      );
  }, []);

  if (redirect) {
    return <Redirect to="/" />;
  }

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <React.Fragment>
      <Grid container direction="row" alignItems="center">
        {rows.map((row, index) => (
          <Grid
            key={row.id + index}
            className={
              (parseInt(index / 3) + 1) % 8 === 0
                ? classes.lastRowHeight
                : classes.rowHeight
            }
            item
            xs={4}
          >
            <Grid
              container
              direction="column"
              alignItems="center"
              justify="space-around"
            >
              <Grid item xs={11}>
                <Barcode className={classes.barcodeImg} value={row.id} />
              </Grid>
              <Grid className={classes.gridItemTexts} item>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justify="space-between"
                >
                  <Grid item xs={4}>
                    <Typography className={classes.text} variant="subtitle1">
                      <b> </b>
                    </Typography>
                    <Typography
                      className={classes.textProdName}
                      variant="subtitle1"
                      align="right"
                    >
                      <b>{row.name}</b>
                    </Typography>
                    <Typography className={classes.text} variant="subtitle1">
                      <b> </b>
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Grid container>
                      <Grid item xs={3} className={classes.imgNumeroBeEG}>
                        <img src="/bemcn.png" style={{ maxHeight: "22px" }} />
                      </Grid>
                      <Grid item xs={9}>
                        <Typography
                          className={classes.text}
                          variant="subtitle1"
                        >
                          lot n°<b>{row.lotNbr}</b>
                        </Typography>
                        <Typography
                          className={classes.text}
                          variant="subtitle1"
                        >
                          Prod. le:{" "}
                          {achat
                            ? new Date(achat.date).toLocaleDateString()
                            : "/"}
                        </Typography>
                        <Typography
                          className={classes.text}
                          variant="subtitle1"
                        >
                          DLC:{" "}
                          {achat
                            ? new Date(achat.endDate).toLocaleDateString()
                            : "/"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>

      <Button
        variant="contained"
        color="primary"
        className={clsx("hidden-print", classes.printBtn)}
        onClick={(_) => window.print()}
      >
        <PrintIcon />
      </Button>

      <Button
        variant="contained"
        color="secondary"
        className={clsx("hidden-print", classes.exitBtn)}
        onClick={(_) => setRedirect(true)}
      >
        <ExitToAppIcon />
      </Button>
    </React.Fragment>
  );
};

export default PrintPage;
