import React, { Component } from 'react';
import './App.css';
import ProvideAirtableContext from './contexts/AirtableContext';
import ProvideCustomizedSnackBarContext from './contexts/CustomizedSnackbarContext';
import ProvideDialogContext from './contexts/DialogContext';
import Paperbase from './Paperbase/Paperbase';

import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import PrintPage from './PrintPage';

const browserHistory = createBrowserHistory();

class App extends Component {
  render() {
    return (
      <div className="App">
        <ProvideAirtableContext>
          <ProvideCustomizedSnackBarContext>
            <ProvideDialogContext>

              <Router history={browserHistory}>
                <Switch>
                  <Route
                    path="/"
                    exact
                    render={_ => (
                      <Paperbase />
                    )}
                  />
                  <Route
                    path="/print/:id"
                    exact
                    render={_ => (
                      <PrintPage />
                    )}
                  />
                  <Redirect to="/" />
                </Switch>
              </Router>

            </ProvideDialogContext>
          </ProvideCustomizedSnackBarContext>
        </ProvideAirtableContext>
      </div>
    );
  }
}

export default App;
